'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import httpStatus from 'http-status';
import { CurrentStepInAuthProcessPayload } from '@grupa-pracuj/tracker-relax/dist/events';
import {
  AlertBox,
  AlertType,
  Button,
  useMediaQuery,
  breakpoints,
  ButtonSize,
  TextInput,
  InputVariant,
  InputSize,
} from '@grupa-pracuj/ui-library-relax';
import { useHttp, useHttpRequest } from '@grupa-pracuj/http-client-relax/axios';

import { useAnalytics } from 'src/analytics';
import useQueryParameters from 'src/hooks/use-query-parameters/use-query-parameters';
import useLocalStorage from 'src/hooks/use-local-storage';
import parseNextStepRoute from 'src/utils/parse-next-step-route';
import { getAuthProcess } from 'src/utils/http-requests';
import translateResponseErrorMessage, {
  defaultErrorMessage,
  requiredErrorMessage,
  ErrorType,
} from 'src/utils/translate-response-error-message';
import { Process } from 'src/utils/http-requests/get-auth-process';
import { emailDataTest, emailLabel, forwardButtonText, forwardButtonDataTest } from './WelcomeForm.constants';
import styles from './WelcomeForm.module.css';

function WelcomeForm() {
  const [, setUserEmail] = useLocalStorage('userEmail', '');
  const { authProcessId, isApplicationProcess, v2, v3 } = useAnalytics();
  const router = useRouter();
  const http = useHttp();
  const { exception, handleRequest } = useHttpRequest(http, getAuthProcess);
  const { createQueryString } = useQueryParameters();
  const [isDesktop] = useMediaQuery(breakpoints.desktop);
  const [email, setEmail] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const handleCheckValidation = (event: React.FocusEvent<HTMLInputElement>) => {
    setEmailValidationError(event.currentTarget.validationMessage);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setEmailValidationError(requiredErrorMessage);

      return;
    }

    setIsLoading(true);

    const { status, data, error } = await handleRequest({ email });

    if (error) {
      setEmailValidationError(translateResponseErrorMessage(error as ErrorType | ErrorType[]));
      setIsLoading(false);

      return;
    }

    if (status === httpStatus.OK && data && email) {
      const { process } = data;

      const processType = [process === Process.Login && 'sign-in', 'sign-up'].find(
        Boolean,
      ) as CurrentStepInAuthProcessPayload['processType'];

      const currentStepInAuthProcessPayload: CurrentStepInAuthProcessPayload = {
        authProcessId,
        processCurrentStage: 'start',
        accountType: 'email',
        processType,
        isApplicationProcess,
      };

      setUserEmail(email);

      const callback = () => {
        // router.push(parseNextStepRoute({ process, authProcessId, createQueryString }));
        v2.setTrackerCallback(() => router.push(parseNextStepRoute({ process, authProcessId, createQueryString })));
        v2.sendCurrentStepInAuthProcess(currentStepInAuthProcessPayload);
      };

      v3.sendCurrentStepInAuthProcess(currentStepInAuthProcessPayload, callback);
    }
  };

  return (
    <form className={styles.welcomeForm} onSubmit={handleOnSubmit} data-test="form-welcome">
      {exception ? <AlertBox type={AlertType.ErrorGlobal} message={defaultErrorMessage} /> : null}
      <div data-test={emailDataTest}>
        <TextInput
          autoComplete="email"
          error={emailValidationError || ''}
          name="email"
          type="email"
          variant={InputVariant.Compact}
          label={emailLabel}
          value={email}
          onBlur={handleCheckValidation}
          onChange={handleSetEmail}
          inputSize={InputSize.L}
        />
      </div>

      <Button
        dataTest={forwardButtonDataTest}
        type="submit"
        wide
        isLoading={isLoading}
        size={isDesktop ? ButtonSize.L : ButtonSize.M}
      >
        {forwardButtonText}
      </Button>
    </form>
  );
}

export default WelcomeForm;
